import { CalendarEvent, CalendarDayFormatter } from "very-simple-calendar/dist/types";

const calendarDayFormatter: CalendarDayFormatter = {
    validateEventsOfDay: (active: boolean, date: Date, eventsOfDay: CalendarEvent[]) => {

        const styleActive = "calendar-item-active";
        const styleInactive = "calendar-item-busy";
        let classNameOfDay = "calendar-item-inactive";
        const countEventsOfDay = eventsOfDay.length;

        if (active) {
            if (date.getDay() === 1) {
                if (countEventsOfDay > 0) {
                    classNameOfDay = styleActive;
                } else {
                    classNameOfDay = styleInactive;
                }
            } else {
                if (countEventsOfDay > 0) {
                    classNameOfDay = styleInactive;
                } else {
                    classNameOfDay = styleActive;
                }
            }
        }

        return {
            className: classNameOfDay
        }
    }
}

export { calendarDayFormatter };