import { useState, useEffect } from "react";
import { CalendarEvent } from "very-simple-calendar/dist/types";

interface DateListResponse {
    closedDates: CalendarEvent[];
}

const useGetClosedDates = () => {

    const [eventList, setEventList] = useState<CalendarEvent[]>([]);

    useEffect(() => {

        fetch('/nautiland-calendar/api/getClosedDates').then(x => x.json())
            .then((data: DateListResponse) => {

                restoreDates(data);
                setEventList(data.closedDates);
            });
    }, []);

    return {
        eventList
    }
};

const restoreDates = (data: DateListResponse) => {
    data.closedDates.forEach(element => {
        element.startDate = new Date(element.startDate);
    });
};

export { useGetClosedDates }