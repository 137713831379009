import React, { FunctionComponent } from 'react';
import { useGetClosedDates } from './useGetClosedDates';
import { Calendar } from 'very-simple-calendar';
import { calendarDayFormatter } from './calendarDayFormatter';

import './App.css';
import 'very-simple-calendar/dist/main.css';
import 'react-toastify/dist/ReactToastify.css';
import { calendarOptions } from './calendarOptions';

const AppReadOnly: FunctionComponent = () => {

  const { eventList } = useGetClosedDates();

  return (
    <div className="App">
      <Calendar events={eventList}
        calendarDayFormatter={calendarDayFormatter}
        options={calendarOptions}
      />
    </div>
  );
}

export { AppReadOnly };
